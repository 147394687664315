import React, {useEffect, useState} from "react";
import {ModalMessage} from "../ModalMessage";
import {OrgHeader} from "../OrgHeader";
import {OrgNavigation} from "../OrgNavigation";
import {CONFIG} from "../../../../CONFIG";
import * as SsoApi from "../../../../requests/SsoAPI";
import {ISubGetResponse} from "../../../types/subs/ISubGetResponse";
import {SubInvite} from "./SubInvite";
import {SubDetails} from "./SubDetails";
import SessionModel from "../../../../stores/SessionModel";
import {BlankPage} from "../BlankPage";

export const Role = () => {
    const [subs, setSubs] = useState<ISubGetResponse[]>([]);
    const [currentSub, setCurrentSub] = useState<ISubGetResponse>();
    const [panel, setPanel] = useState<string | undefined>();
    const [error, setError] = useState("");

    useEffect(() => {
        getSubs();
    }, []);

    const getSubs = () => {
        SsoApi.getSubs((response) => {
            if (response.status === 200) {
                setSubs(response.data);
            } else {
                setError(response.data.message);
            }
        });
    }

    const hideAll = () => {
        setCurrentSub(undefined);
        setPanel(undefined);
    }

    const showSub = (s: ISubGetResponse) => {
        setCurrentSub(s);
        setPanel("sub");
    }

    const deleteSub = (id: number) => {
        if (!confirm("Вы действительно желаете удалить администратора?")) return;
        SsoApi.deleteSub(id, (response) => {
            if (response.status === 200) {
                window.location.reload()
            } else {
                setError(response.data.message);
            }
        })
    }

    const getRoleList = (s: ISubGetResponse) => {
        let roleList = new Array<string>();
        s.rolesChallenge.forEach(rc => {
            switch (rc) {
                case "MASTER": roleList.push(" полные права (создание новых мероприятий и редактирование уже существующих)"); break;
                case "CHALLENGE": roleList.push(" администрирование ранее созданных мероприятий (по списку)"); break;
                case "CREATOR": roleList.push(" создание новых мероприятий (без доступа к уже существующим)"); break;
            }
        });
        s.rolesTest.forEach(rt => {
            switch (rt) {
                case "CREATOR": roleList.push(" создание и редактирование новых тестов и просмотр уже существующих"); break;
                case "TEST": roleList.push(" редактирование ранее созданных тестов (по списку)"); break;
            }
        })
        if (s.rolesCertificate.length > 0) roleList.push(" создание и редактирование новых сертификатов (без доступа к созданным другими админами)");

        if (roleList.length === 0) {
            return "нет ролей";
        } else {
            return roleList.toString().trim();
        }
    }

    return (
        <div className="platform-background">
            <video autoPlay muted loop playsInline className="image-back-video">
                <source src={require("../../../../../public/images/main_video.mp4").default}/>
            </video>
            <ModalMessage header={"Ошибка"} msg={error} _callback={() => setError("")}/>
            <div className="left-block">
                <OrgHeader preview={null}/>
                <main className="content">
                    <OrgNavigation />
                    <div className="main-list-center-comand">
                        <button className="button-add" id="add" onClick={() => setPanel("invite")}>
                            Добавить
                        </button>

                        <div className="my-challenge">
                            <div className="user-info">
                                <p className="fio-or-name">Администрирование</p>
                            </div>
                            
                        </div>
                        <div className="challenge-info">
                            <div className="first-message">
                                <p className="city">
                                    В этом разделе вы можете добавлять администраторов мероприятий. <a href="https://disk.yandex.ru/d/qa_yiJSmaUo3hw" target="_blank" className="active-link">Видеоинструкция</a>
                                </p>
                            </div>
                            <ul className="challenge-list">
                                {subs.map((s, index) =>
                                    <li className="challenge-card" key={index}>
                                        <div className="challenge-card-main">
                                            <img onClick={() => showSub(s)}
                                                 src={CONFIG.STORAGE_URL + "/" + s.subId + "/avatar/" + s.avatar}
                                                 onError={({currentTarget}) => {currentTarget.onerror = null; currentTarget.src="/images/no_ava_user.svg"}}
                                                 alt=""
                                                 className="challenge-avatar"/>
                                            <div className="user-info" onClick={() => showSub(s)}>
                                                <p className="challenge-header">{!s.firstname ? "Запрос отправлен" : `${s.lastname} ${s.firstname}`}
                                                </p>
                                                <p className="city">{s.email}</p>
                                            </div>
                                        </div>
                                        {
                                            s.rolesChallenge.length > 0 || s.rolesTest.length > 0 || s.rolesCertificate.length > 0 ?
                                                <div className="block-input">
                                                    <p className="legend-about">
                                                        Права
                                                    </p>
                                                    <ul className="interests">
                                                        {getRoleList(s)}
                                                    </ul>
                                                </div>
                                                :
                                                <></>
                                        }
                                        <div className="stage-card-new-wrapper-link">
                                            <a onClick={() => deleteSub(s.subId)} className="card-standart-link-false">
                                                Удалить администратора
                                            </a>
                                        </div>
                                    </li>
                                )}
                            </ul>

                        </div>
                    </div>
                </main>
            </div>

            {
                panel === "invite" ? <SubInvite close={hideAll}/> : ""
            }

            {
                panel === "sub" && currentSub ? <SubDetails close={hideAll} sub={currentSub}/> : ""
            }
        </div>
    )
}