import {action, observable, makeObservable, computed} from 'mobx'
import cookie from 'js-cookie'
import {toNumber} from "lodash";

export const SESSION_TOKEN_KEY = 'session_token'

export default class SessionModel {
  @observable isLogged: boolean
  //role: string

  constructor() {
    makeObservable(this)
    this.isLogged = false
  }

  protected onChangeExpressionOfReaction = () => {
    return [this.isLogged]
  }


  @action
  updateIsLogged(value: boolean) {
    this.isLogged = value
  }


  static saveToken(token: string, rememberMe?: boolean) {
    if (token) {
      /*if (rememberMe) {
        cookie.set(SESSION_TOKEN_KEY, token, { expires: 365 })
      } else {*/
        cookie.set(SESSION_TOKEN_KEY, token)
      //}
    }
  }

  static getToken() {
    if(localStorage.getItem('token') !== null)
      return localStorage.getItem('token');
    return "";
    // if (typeof window !== 'undefined') {
    //   return sessionStorage.getItem('auth_token')
    // }
  }

  static removeToken() {
    if (typeof window !== 'undefined') {
      localStorage.removeItem('token');
      sessionStorage.removeItem('token');
    }
  }

  static getCurrentUserAuthData() {
    let userData = this.getToken()?.split(".")[1] || "";
    if(userData !== "") {
      return JSON.parse(atob(userData).toString());
    } else {
      cookie.set("afterLoginRedirect", window.location.pathname);
      window.location.href="/signup";
    }
  }

  static getDomId() {
    return toNumber(SessionModel.getCurrentUserAuthData().domId);
  }

  static getUserId() {
    return toNumber(SessionModel.getCurrentUserAuthData().id);
  }

  static isAdmin() {
    return SessionModel.getCurrentUserAuthData().roles.includes("ROLE_ADMIN");
  }

  static isOrg() {
    return SessionModel.getCurrentUserAuthData().roles.includes("ROLE_ORG");
  }

  static isEdu() {
    return SessionModel.getCurrentUserAuthData().roles.includes("ROLE_EDU");
  }

  static isBsn() {
    return SessionModel.getCurrentUserAuthData().roles.includes("ROLE_BSN");
  }

  static isModerator() {
    return SessionModel.getCurrentUserAuthData().roles.includes("ROLE_MODERATOR");
  }

  static isPersonal() {
    return SessionModel.getCurrentUserAuthData().roles.includes("ROLE_USER");
  }

  static isSub() {
    return SessionModel.getCurrentUserAuthData().roles.includes("ROLE_SUB");
  }

  static isActive() {
    return SessionModel.getCurrentUserAuthData().isActive;
  }
}
