export function formatDate(dateString: string | undefined) {
  if (dateString) {
    let date = new Date(dateString);
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('.');
  } else { return ""}
}

export function formatYYYYmmDD(dateString: string | undefined) {
  if (dateString) {
    let date = new Date(dateString);
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-');
  }
}

export function formatDateTime(dateString: string | undefined) {
  if (dateString) {
    let date = new Date(dateString);
    const dmy = [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('.');
    return dmy + " " + padTo2Digits(date.getHours()) + ":" + padTo2Digits(date.getMinutes());
  } else { return ""}
}

export function toHoursAndMinutes(min: number) {
  const minutes = min % 60;
  const hours = Math.floor(min / 60);

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`;
}

export function toHoursAndMinutesFromSec(sec: number) {
  const totalMinutes = Math.floor(sec / 60);

  const seconds = Math.floor(sec % 60);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return { h: padTo2Digits(hours), m: padTo2Digits(minutes), s: padTo2Digits(seconds) };
}

export function differenceInMin(date1: string, date2: string) {
  var startDate = new Date(date1);
  var endDate = new Date(date2);
  // @ts-ignore
  var diffMs = (endDate - startDate);
  var diffDays = Math.floor(diffMs / 86400000); // days
  var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
  var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
  return diffMins;
}

export function differenceHMS(date1: string, date2: string) {
  var startDate = new Date(date1);
  var endDate = new Date(date2);
  // @ts-ignore
  var diffMs = (endDate - startDate);
  var diffSec = Math.floor(diffMs / 1000);
  return toHoursAndMinutesFromSec(diffSec);
}

export interface IHms {
  h: string;
  m: string;
  s: string;
}

export function getStringMonth(dateString: string) {
  const months = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"];
  let date = new Date(dateString);
  return months[date.getMonth()];
}

export function getDaysHoursMinutes(minutes: number)  {
  if(Math.sign(minutes) != -1){
    var mins= minutes % 60;
    var hours = Math.floor(minutes / 60);
    var days= Math.floor(hours / 24);
    var hourss = hours % 24;
    return days + ' д. ' + hourss + ' ч. '+ mins + " м.";
  }
  else{
    var absTotal= Math.abs(minutes);
    var mins= absTotal % 60;
    var hours = Math.floor(absTotal / 60);
    var days= Math.floor(hours / 24);
    var hourss = hours % 24;
    return days + ' д. ' + hourss + ' ч. '+ mins + " м.";
  }
}

function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0');
}
