import React, {useEffect} from "react";
import * as SsoAPI from "../../../requests/SsoAPI";
import API from "../../../requests/_axios";
import cookie from "js-cookie";
import {useNavigate, useSearchParams} from "react-router-dom";

export const BPeremenaSignup = () => {

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        let afterLoginRedirect = "/profile";
        if (searchParams.get("cid")) {
            afterLoginRedirect = `/challenge/${searchParams.get("cid")}/my`;
        }
        cookie.set("afterLoginRedirect", afterLoginRedirect);
        API.post("/sso/api/v1/user").then((response) => {
            if (response.status === 200)
                navigate(afterLoginRedirect);
            else {
                localStorage.removeItem("token");
                cookie.remove("token");
                SsoAPI.getBPeremenaSignInLink(makeState(16), (response) => {
                    window.location.href = response.data;
                })
            }
        })
    }, []);

    const makeState = (length: number) : string => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    return (<></>);
}