import {useNavigate, useSearchParams} from "react-router-dom";
import React, {useEffect} from "react";
import * as SsoAPI from "../../../requests/SsoAPI";
import {Spinner} from "../../profile/common/Spinner";

export const BPeremenaCallback = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        const state = searchParams.get("state");
        const code = searchParams.get("code")
        if (state && code) {
            SsoAPI.getBPeremenaCallback(state, code, (response) => {
                if (response.status === 200) {
                    localStorage.removeItem("token");
                    localStorage.setItem("token", response.data.authData.token);
                    if (response.data.profileDataB64) {
                        navigate(`/signup?step=3&pdata=${encodeURIComponent(response.data.profileDataB64)}`)
                    } else {
                        navigate("/profile");
                    }
                }
            });
        }
    }, []);

    return (
        <div className="platform-background">
            <Spinner text={"Подождите..."} enabled={true}/>
        </div>
    );
}