import React, {useEffect, useState} from "react";
import {TestSettings} from "./right/TestSettings";
import * as TestAPI from "../../../../requests/TestAPI";
import * as ChallengeAPI from "../../../../requests/ChallengeAPI";
import {ModalMessage} from "../../common/ModalMessage";
import {ITest} from "../../../types/ITest";
import {TopicSettings} from "./right/TopicSettings";
import {ITopicSetting} from "../../../types/ITopicSetting";
import plural from "plural-ru";
import {useSearchParams} from "react-router-dom";
import {Testing} from "../../common/testing/Testing";
import {OrgHeader} from "../../common/OrgHeader";
import {OrgNavigation} from "../../common/OrgNavigation";
import {getDaysHoursMinutes} from "../../../helpers/DateHelper";
import SessionModel from "../../../../stores/SessionModel";
import {BlankPage} from "../../common/BlankPage";
import {ITestSubRoleList} from "../../../types/subs/ITestSubRoleList";

export const TestList = () => {

  const [rightBlockTestStyle, setRightBlockTestStyle] = useState("-100vw");
  const [rightBlockTopicStyle, setRightBlockTopicStyle] = useState("-100vw");
  const [rightBlockTestingStyle, setRightBlockTestingStyle] = useState("-100vw");
  const [filter, setFilter] = useState("");
  const [currentTestId, setCurrentTestId] = useState<string | null>();
  const [currentTopicId, setCurrentTopicId] = useState<string | null>();
  const [currentTopicTest, setCurrentTopicTest] = useState<ITest | null>();
  const [currentTestingId, setCurrentTestingId] = useState<string | null>();
  const [error, setError] = useState("");
  const [tests, setTests] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [hasTestPlan, setHasTestPlan] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [subRoleResponse, setSubRoleResponse] = useState<ITestSubRoleList>();

  useEffect(() => {
    getTestPlan();
    setFilter(searchParams.get("filter") || "draft");
    fetchTestList();
  }, []);

  const getTestPlan = () => {
    TestAPI.getActivePlan((response) => {
      if (response.status === 200) {
        setHasTestPlan(true);
      }
    });
    ChallengeAPI.getProfile((response) => {
      if (response.status === 200) {
        setIsApproved(response.data.isApproved)
      }
    });
    TestAPI.getSubRoleList(null, (response) => {
      if (response.status === 200) {
        setSubRoleResponse(response.data);
      }
    });
  }

  const fetchTestList = () => {
    TestAPI.getTestList(searchParams.get("filter") || "draft", (response) => {
      if (response.status === 200) {
        setTests(response.data);
      } else {
        setError("Ошибка при получении списка тестов");
      }
    });
  }

  const publish = (testId: string) => {
    TestAPI.publishTest(testId, (response) => {
      if (response.status === 200) {
        fetchTestList();
      } else {
          setError(response.data.message);
      }
    })
  }

  const toggleTestSettings = (testId: string | null) => {
    setCurrentTestId(testId);
    if (testId !== null) {
      setRightBlockTopicStyle("-100vw");
      setRightBlockTestingStyle("-100vw");
      setRightBlockTestStyle("0");
    } else {
      setRightBlockTestStyle("-100vw");
      setRightBlockTopicStyle("-100vw");
      setRightBlockTestingStyle("-100vw");
    }
  }

  const toggleTopicSettings = (topicId: string | null, test: ITest | null) => {
    setCurrentTopicId(null);
    setTimeout(() => {setCurrentTopicId(topicId)}, 0);
    setCurrentTopicTest(test);
    if (topicId !== null) {
      setRightBlockTopicStyle("0");
      setRightBlockTestStyle("-100vw");
      setRightBlockTestingStyle("-100vw");
    } else {
      setRightBlockTestStyle("-100vw");
      setRightBlockTopicStyle("-100vw");
      setRightBlockTestingStyle("-100vw");
    }
  }

  const toggleTesting = (testingId: string | null) => {
    setCurrentTopicId(null);
    setCurrentTopicTest(null);
    setCurrentTestingId(testingId);
    if (testingId !== null) {
      setRightBlockTopicStyle("-100vw");
      setRightBlockTestStyle("-100vw");
      setRightBlockTestingStyle("0");
    } else {
      setRightBlockTestStyle("-100vw");
      setRightBlockTopicStyle("-100vw");
      setRightBlockTestingStyle("-100vw");
    }
  }

  const deleteTest = (testId: string) => {
    if(confirm("Уверены, что хотите удалить?")) {
      TestAPI.deleteTest(testId, (response) => {
        if (response.status !== 200) {
          setError("Ошибка при удалении теста");
        } else {
          fetchTestList();
        }
      });
    }
  }

  const getTestQuestionsNum = (test: ITest) => {
    let questions = 0;
    test.topicsSettings?.map((setting: ITopicSetting) =>
        questions += setting.topic.questions?.length || 0
    )
    return plural(questions, "%d вопрос", "%d вопроса", "%d вопросов");
  }

  const getMaxPoints = (test: ITest) => {
    let maxPoints = 0;
    test.topicsSettings?.map((setting: ITopicSetting) =>
      maxPoints += (setting.pointsPerQuestion || 0) * (setting.questionNum || 0)
    )
    return maxPoints;
  }

  const getParticipantQuestionsNum = (test: ITest) => {
    let questions = 0;
    test.topicsSettings?.map((setting: ITopicSetting) =>
      questions += setting.questionNum || 0
    )
    return questions;
  }

  const getTestTime = (minutes: number) => {
    let m = minutes % 60;
    let h = (minutes - m) / 60;
    return (h < 10 ? "0" : "") + h.toString() + ":" + (m < 10 ? "0" : "") + m.toString();

  }

  const runTest = (testId: string) => {
    TestAPI.runTest(testId, (response) => {
      if (response.status === 200) {
        toggleTesting(response.data);
      } else {
        setError("Ошибка при запуске теста");
      }
    });
  }

  const canEditTest = (test: ITest | undefined) => {
    if (SessionModel.isAdmin() || SessionModel.isOrg()) {
      return true;
    } else if (test && test.id && SessionModel.isSub()) {
      const testInList = subRoleResponse?.subRoleTests.filter(srt => srt.testId === test.id);
      return (testInList && testInList.length > 0) || test.subId === SessionModel.getUserId();
    }
    return false;
  }

  const canCreateTest = () => {
    return SessionModel.isAdmin() || SessionModel.isOrg() || (SessionModel.isSub() && subRoleResponse?.subRole.roles.includes("CREATOR"));
  }


  if ((SessionModel.isOrg() || SessionModel.isSub()) && !hasTestPlan && !isApproved) {
    return <BlankPage message={"В этом разделе вы можете создавать любое количество тестов для ваших мероприятий и других инициатив, созданных в разделе \"Мероприятия\". Для доступа к функционалу вам необходимо подключить данный сервис в разделе \"Тарифы\", далее \"Тарифы\", \"Дополнительные сервисы\""}/>
  } else if ((SessionModel.isOrg() || SessionModel.isSub()) && !hasTestPlan && isApproved) {
    return <BlankPage message={"В этом разделе вы можете создавать любое количество тестов для ваших мероприятий и других инициатив, созданных в разделе \"Мероприятия\". Для доступа к функционалу вам необходимо подключить данный сервис в разделе \"Тарифы\", далее \"Тарифы\", \"Дополнительные сервисы\". Посмотрите <a href=\"https://disk.yandex.ru/d/dMXzNri5lEStZQ\" target=\"_blank\" class=\"active-link\">видеообзор</a> сервиса \"Тестирование\""}/>
  } else {
    return (
        <>
          <ModalMessage msg={error} header={"Ошибка"} _callback={() => setError("")}/>
          <div className="platform-background">
            <video autoPlay muted loop playsInline className="image-back-video">
              <source src={require("../../../../../public/images/main_video.mp4").default}/>
            </video>
            <div className="left-block">
              <OrgHeader preview={null}/>
              <main className="content">
                <OrgNavigation />
                <div className="main-list-center">
                  {
                    canCreateTest() ?
                        <button className="button-add" onClick={() => toggleTestSettings("")}>
                          Создать
                        </button>
                        :
                        <></>
                  }

                  <div className="my-challenge">
                    <div className="user-info">
                      <p className="fio-or-name">Мои тестирования</p>
                    </div>
                    <ul className="nav-block-center">
                      <li className="nav-center-item">
                        <a href="?filter=published" className={filter === "published" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"}>Сохраненные</a>
                      </li>
                      <li className="nav-center-item">
                        <a href="?filter=draft" className={filter === "draft" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"}>Черновики</a>
                      </li>
                    </ul>
                  </div>
                  <div className="challenge-info">
                    {!SessionModel.isAdmin() ?
                        <div className="first-message">
                          <p className="city">
                            <a href="https://disk.yandex.ru/d/dMXzNri5lEStZQ" target="_blank" className="active-link">Видеоинструкции</a> по работе с сервисом "Тестирование"
                          </p>
                        </div>
                        :
                        <></>
                    }
                    {tests?.map((test: ITest) =>
                        <div className="btn-stage" key={test.id}>
                          <div className="row-wrapper">
                            <p className="stage-card-heading">
                              {getTestQuestionsNum(test)}
                            </p>
                            {filter === "draft" && canEditTest(test) ? <a className="delete-stage" onClick={() => deleteTest(test.id || "")} style={{cursor: "pointer"}}>Удалить</a> : ""}
                          </div>
                          <h2 className="stage-card-name" style={{cursor: "pointer"}} onClick={() => canEditTest(test) ? toggleTestSettings(test.id || "") : void(0)}>
                            {test.name}
                          </h2>
                          {
                            canEditTest(test) ?
                                <h4 className="btn-info-heading-plus"
                                    onClick={() => toggleTopicSettings("", test || null)}
                                    style={{display: test.topicsSettings ? "none" : ""}}>
                                  Добавить тему
                                </h4>
                                :
                                <></>
                          }
                          {test.topicsSettings ? <>
                            <p className="stage-card-heading">Максимальный балл<span
                                className="test-point"> {getMaxPoints(test)}</span></p>
                            <p className="stage-card-heading">Количество вопросов<span className="test-point"> {getParticipantQuestionsNum(test)}</span></p>
                            <p className="stage-card-heading">Время на тестирование<span className="test-time"> {getDaysHoursMinutes(test.settings ? test.settings[0].time || 0 : 0)}</span></p>
                          </> : ""}
                          {
                            SessionModel.isSub() && !canEditTest(test) ? <></> :
                            <div className="btn-info" style={{cursor: "pointer"}}>
                              <h4 className="btn-info-settings" onClick={() => toggleTestSettings(test.id || "")}>
                                Настройки тестирования
                              </h4>
                              <p className="btn-info-text">
                                Здесь вы можете изменить название и время тестирования, а также требуемые от участников навыки
                              </p>
                            </div>
                          }
                          <div className="btn-info">
                            <h4 className="btn-info-edit">
                              Темы и вопросы
                            </h4>
                            <p className="btn-info-text">
                              Здесь вы можете создавать и редактировать темы и вопросы теста
                            </p>
                            <ul className="interests">
                              <ul className="interests">
                                <h4 className="btn-info-heading" style={{display: test.topicsSettings ? "none" : ""}}>
                                  У вас нет ни одной темы
                                </h4>
                                {test.topicsSettings?.map((setting: ITopicSetting) =>
                                    <li className="interest-item-theme" key={setting.id} onClick={() => toggleTopicSettings(setting.topicId || "", test || "")}>
                                      {setting.topic.name} <span className="theme-number">{setting.topic.questions?.length}</span>
                                    </li>
                                )}
                              </ul>
                              {
                                SessionModel.isSub() && !canEditTest(test) ? <></> :
                                    <li className="interest-item plus-theme"
                                        style={{display: test.topicsSettings ? "" : "none"}}
                                        onClick={() => toggleTopicSettings("", test || null)}>
                                      Добавить тему
                                    </li>
                              }
                            </ul>
                          </div>
                          {
                            SessionModel.isSub() && !canEditTest(test) ? <></> :
                                <input className="btn-blue" type="button"
                                       value={filter === "draft" ? "Сохранить" : "Снять с публикации"}
                                       onClick={() => publish(test.id || "")}/>
                          }
                          <input className="btn-main" type="button" value="Пройти" onClick={() => runTest(test.id || "")}/>
                        </div>
                    )}
                  </div>
                </div>
              </main>
            </div>

            {currentTestingId ?
                <div className="right-block" style={{right: rightBlockTestingStyle}}>
                  <header className="header">
                    <p className="header-back" id="backmenu" onClick={() => toggleTesting(null)}>Назад</p>
                    <p className="name-menu">Тестирование</p>
                  </header>
                  <Testing challengeId={null} stageId={null} testingId={currentTestingId}/>
                </div>
                :
                <></>
            }

            {currentTestId !== null && currentTestId !== undefined ?
                <div className="right-block" style={{right: rightBlockTestStyle}}>
                  <header className="header">
                    <p className="header-back" id="backmenu" onClick={() => toggleTestSettings(null)}>Назад</p>
                    <p className="name-menu">Тестирование</p>
                  </header>
                  <TestSettings testId={currentTestId}/>
                </div>
                :
                <></>
            }

            {currentTopicId !== null && currentTopicId !== undefined && currentTopicTest ?
                <div className="right-block" style={{right: rightBlockTopicStyle}}>
                  <header className="header">
                    <p className="header-back" id="backmenu" onClick={() => toggleTopicSettings(null, null)}>Назад</p>
                    <p className="name-menu">Вопросы</p>
                  </header>
                  <TopicSettings canEdit={canEditTest(currentTopicTest)} topicId={currentTopicId} testId={currentTopicTest.id || ""} refresh={fetchTestList}/>
                </div>
                :
                <></>
            }
          </div>
        </>
    )
  }
}