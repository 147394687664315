import API from "./_axios";
import {IMakeSubRequest} from "../components/types/subs/IMakeSubRequest";

export function loginAs(username: string) {
    API.post<any>('/sso/api/v1/signin/as', {username: username}).then((response) => {
        if(response.status === 200) {
            localStorage.removeItem("token");
            localStorage.setItem("token", response.data.token);
            setTimeout(() => { window.location.href="/profile"; }, 100 );
        } else if (response.status === 401) {
            alert("Неправильный логин или пароль");
        } else if (response.status === 403) {
            alert(response.data.message);
        } else {
            alert("Произошла ошибка");
        }
    })
}

export function getSubs(_callback: (response: any) => void) {
    API.get(`/sso/api/v1/role/list`).then((response) => {
        _callback(response);
    })
}

export function makeSubRequest(request: IMakeSubRequest, _callback: (response: any) => void) {
    API.post(`/sso/api/v1/role`, request).then((response) => {
        _callback(response);
    })
}

export function deleteSub(subId: number, _callback: (response: any) => void) {
    API.delete(`/sso/api/v1/role/sub/${subId}`).then((response) => {
        _callback(response);
    })
}

export function getSubRequest(_callback: (response: any) => void) {
    API.get(`/sso/api/v1/role/request/`).then((response) => {
        _callback(response);
    })
}

export function acceptSubRequest(requestId: number, _callback: (response: any) => void) {
    API.post(`/sso/api/v1/role/request/${requestId}`).then((response) => {
        _callback(response);
    })
}

export function rejectSubRequest(requestId: number, _callback: (response: any) => void) {
    API.delete(`/sso/api/v1/role/request/${requestId}`).then((response) => {
        _callback(response);
    })
}

export function getBPeremenaSignInLink(state: string, _callback: (response: any) => void) {
    API.get(`/sso/api/v1/partner/bperemena/signin?state=${state}`).then((response) => {
        _callback(response);
    })
}

export function getBPeremenaCallback(state: string, code: string, _callback: (response: any) => void) {
    API.get(`/sso/api/v1/partner/bperemena/callback?state=${state}&code=${code}`).then((response) => {
        _callback(response);
    })
}