import API from "./_axios";
import SessionModel from "../stores/SessionModel";
import {IPublicStageResult} from "../components/types/IPublicStageResult";
import {IChallengeBranding} from "../components/types/IChallengeBranding";
import {IAdminPlan} from "../components/types/IAdminPlan";
import {IUserPlan} from "../components/types/IUserPlan";
import {IChallengeStageSolution} from "../components/types/IChallengeStageSolution";
import {ICreateJuryRequest} from "../components/types/jury/ICreateJuryRequest";
import {IAssessmentSettings} from "../components/types/assessment/IAssessmentSettings";
import {ICreateMentorRequest} from "../components/types/mentor/ICreateMentorRequest";
import {AxiosResponse} from "axios";
import {IJuryAssessmentList} from "../components/types/assessment/IJuryAssessmentList";
import {IJuryAssessment} from "../components/types/assessment/IJuryAssessment";
import {IJury} from "../components/types/jury/IJury";
import {IGrantRoleRequest} from "../components/types/subs/IGrantRoleRequest";
import {IBanRequest} from "../components/types/IBanRequest";

export function unloadStudents(password: string, visibleOnly: number, _callback: (status: number) => void)  {
    API.post(`/challenge/api/v1/xls/individuals?visibleOnly=${visibleOnly}`,
        {"username": SessionModel.getCurrentUserAuthData().sub, "password": password},
        {headers: {"Authorization": "Bearer " + localStorage.getItem("token")}}).then((response) => {
        if (response.status === 200) {
            var a = document.createElement('a');
            var fname = visibleOnly === 1 ? `${new Date().toISOString().split('T')[0]}_public.xlsx` : `${new Date().toISOString().split('T')[0]}_all.xlsx`
            a.download = fname;
            a.href = `data:application/vnd.ms-excel;base64,${response.data.fileBase64}`;
            a.click();
        }
        _callback(response.status);
    });
}

export function unloadUniversities(password: string, _callback: (status: number) => void) {
    API.post(`/challenge/api/v1/xls/universities`,
        {"username": SessionModel.getCurrentUserAuthData().sub, "password": password},
        {headers: {"Authorization": "Bearer " + localStorage.getItem("token")}}).then((response) => {
        if (response.status === 200) {
            const a = document.createElement('a');
            a.download = `${new Date().toISOString().split('T')[0]}_universities.xlsx`;
            a.href = `data:application/vnd.ms-excel;base64,${response.data.fileBase64}`;
            a.click();
        }
        _callback(response.status);
    })
}

export function unloadBusiness(password: string, _callback: (status: number) => void) {
    API.post(`/challenge/api/v1/xls/business`,
        {"username": SessionModel.getCurrentUserAuthData().sub, "password": password},
        {headers: {"Authorization": "Bearer " + localStorage.getItem("token")}}).then((response) => {
        if (response.status === 200) {
            const a = document.createElement('a');
            a.download = `${new Date().toISOString().split('T')[0]}_business.xlsx`;
            a.href = `data:application/vnd.ms-excel;base64,${response.data.fileBase64}`;
            a.click();
        }
        _callback(response.status);
    })
}

export function unloadChallenge(challengeId: number, universityId: number, _callback: (status: any) => void) {
    API.get(`/challenge/api/v1/xls/challenge/${challengeId}?universityId=${universityId}`).then((response) => {
        _callback(response);
    })
}

export function getChallengeById(id: number, _callback: (response: any) => void) {
    API.get(`/challenge/api/v1/challenge/${id}`).then((response) => {
        if (response.status === 404) {
            window.location.href="/404";
        } else {
            _callback(response);
        }
    });
}

export function fetchChallenges(name: string, filter: string, ownerId: string | undefined, _callback: (challengeList: any) => void) {
    API.get(`/challenge/api/v1/challenge?search=${name}&filter=${filter}&ownerId=${ownerId || ""}`).then((response) => {
        _callback(response);
    });
}

export function updateLastSeenIndividual() {
    API.post("/challenge/api/v1/personal/profile/last-seen", {}).then((response) => {});
}

export function updateLastSeenUniversity() {
    API.post("/challenge/api/v1/university/profile/last-seen", {}).then((response) => {});
}

export function fetchCertificateYears(type: string, _callback: (yearList: any) => void) {
    API.get(`/challenge/api/v1/certificate/years?type=${type}`).then((response) => {
        _callback(response);
    })
}

export function fetchCertificateChallenges(type: string, year: number, _callback: (yearList: any) => void) {
    API.get(`/challenge/api/v1/certificate/challenges?type=${type}&year=${year}`).then((response) => {
        _callback(response);
    })
}

export function fetchCertificateByChallenge(type: string, challengeId: number, _callback: (yearList: any) => void) {
    API.get(`/challenge/api/v1/certificate/challenge/${challengeId}?type=${type}`).then((response) => {
        _callback(response);
    })
}

export function getAdminTests(_callback: (response: any) => void, filter?: string) {
    API.get(`/challenge/api/v1/test/list?filter=${filter ? filter : "published"}`).then((response) => {
    if (response.status === 200)
        _callback(response);
    })
}

export function runTest(challengeId: number, stageId: number, _callback: (response: any) => void) {
    API.post(`/challenge/api/v1/test/${challengeId}/${stageId}/run`).then((response) => {
        _callback(response);
    });
}

export function refreshTestResults(challengeId: number, stageId: number, _callback: (response: any) => void) {
    API.post(`/challenge/api/v1/test/${challengeId}/${stageId}/results`).then((response) => {
        _callback(response);
    });
}

export function getTestResult(challengeId: number, stageId: number, _callback: (response: any) => void) {
    API.post(`/challenge/api/v1/test/${challengeId}/${stageId}/result`).then((response) => {
        _callback(response);
    });
}

export function getActivePlan(_callback: (response: any) => void) {
    API.get(`/challenge/api/v1/plan`).then((response) => {
        _callback(response);
    });
}

export function getPlans(_callback: (response: any) => void) {
    API.get(`/challenge/api/v1/plan/all`).then((response) => {
        _callback(response);
    });
}

export function switchPlan(planId: string, _callback: (response: any) => void) {
    API.post(`/challenge/api/v1/plan/${planId}`).then((response) => {
        _callback(response);
    });
}

export function getProfile(_callback: (response: any) => void) {
    let url = "/challenge/api/v1/university/profile";
    if (SessionModel.isEdu()) {
        url = "/challenge/api/v1/university/profile";
    } else if (SessionModel.isOrg()) {
        url = "/challenge/api/v1/business/profile";
    } else {
        url = "/challenge/api/v1/personal/profile";
    }
    API.get(url).then((response) => {
        _callback(response);
    });
}

export function getPublicResults(id: string, _callback: (response:any) => void) {
    API.get(`/challenge/api/v1/public/results/${id}`).then((response) => {
        _callback(response);
    })
}

export function getPublicResultByStageId(stageId: number, _callback: (response:any) => void) {
    API.get(`/challenge/api/v1/public/results/stage/${stageId}`).then((response) => {
        _callback(response);
    })
}

export function getPublicResultSettings(challengeId: number, stageId: number, _callback: (response:any) => void) {
    API.get(`/challenge/api/v1/challenge/${challengeId}/stage/${stageId}/public_result_settings`).then((response) => {
        _callback(response);
    })
}



export function updatePublicResultSettings(challengeId: number, stageId: number, dto: IPublicStageResult, _callback: (response:any) => void) {
    API.post(`/challenge/api/v1/challenge/${challengeId}/stage/${stageId}/public_result_settings`, dto).then((response) => {
        _callback(response);
    })
}

export function uploadPublicResultFile(challengeId: number, stageId: number, formData: FormData, type: string, _callback: (response:any) => void) {
    API.post(`/challenge/api/v1/challenge/${challengeId}/stage/${stageId}/public_result_settings/${type}`, formData).then((response) => {
        _callback(response);
    });
}

export function publishPublicResult(challengeId: number, stageId: number, _callback: (response:any) => void) {
    API.post(`/challenge/api/v1/challenge/${challengeId}/stage/${stageId}/public_result_settings/publish`).then((response) => {
        _callback(response);
    });
}

export function getStage(challengeId: number, stageId: number, _callback: (response:any) => void) {
    API.get(`/challenge/api/v1/challenge/${challengeId}/stage/${stageId}`).then((response) => {
        _callback(response);
    });
}

export function getRating(challengeId: number, stageId: number, _callback: (response:any) => void) {
    API.get(`/challenge/api/v1/challenge/${challengeId}/stage/${stageId}/rating`).then((response) => {
        _callback(response);
    });
}

export function getMyTeam(challengeId: number, _callback: (response:any) => void) {
    API.get(`/challenge/api/v1/challenge/${challengeId}/team/my`).then((response) => {
        _callback(response);
    });
}

export function getMyChallenge(challengeId: number, _callback: (response:any) => void) {
    API.get(`/challenge/api/v1/challenge/${challengeId}/my`).then((response) => {
        _callback(response);
    });
}

export function updateLastSeenBusiness() {
    API.post("/challenge/api/v1/business/profile/last-seen", {}).then((response) => {});
}

export function getProfileById(profileType: string, id: number, _callback: (response: any) => void) {
    API.get(`/challenge/api/v1/${profileType}/profile/${id}`).then((response) => {
        _callback(response);
    });
}

export function updateChallengeBranding(challengeId: number, dto: IChallengeBranding | undefined, _callback: (response:any) => void) {
    API.post(`/challenge/api/v1/challenge/${challengeId}/branding`, dto).then((response) => {
        _callback(response);
    })
}

export function getChallengeBranding(challengeId: number, _callback: (response: any) => void) {
    API.get(`/challenge/api/v1/challenge/${challengeId}/branding`).then((response) => {
        _callback(response);
    });
}

export function deleteBrandingBackground(challengeId: number, _callback: (response: any) => void) {
    API.delete(`/challenge/api/v1/challenge/${challengeId}/branding/background`).then((response) => {
        _callback(response);
    });
}

export function changeVisibilityLevel(challengeId: number, _callback: (response: any) => void) {
    API.post(`/challenge/api/v1/challenge/${challengeId}/visibility-level`).then((response) => {
        _callback(response);
    });
}

export function createPlan(plan: IAdminPlan, _callback: (response: any) => void) {
    API.post(`/challenge/api/v1/plan`, plan).then((response) => {
        _callback(response);
    });
}

export function deletePlan(planId: string, _callback: (response: any) => void) {
    API.delete(`/challenge/api/v1/plan/${planId}`).then((response) => {
        _callback(response);
    });
}

export function getTestDetails(challengeId: number, stageId: number, challengeStageResultId: number, _callback: (response: any) => void) {
    API.get(`/challenge/api/v1/test/${challengeId}/${stageId}/${challengeStageResultId}/unload`).then((response) => {
        _callback(response);
    });
}

export function getMerchSizes(_callback: (response: any) => void) {
    API.get(`/challenge/api/v1/personal/profile/dict/merch-size`).then((response) => {
        _callback(response);
    });
}

export function deleteChallenge(challengeId: number, _callback: (response: any) => void) {
    API.delete(`/challenge/api/v1/challenge/${challengeId}`).then((response) => {
        _callback(response);
    });
}

export function doChallengeMailing(id: number, _callback: (response: any) => void) {
    API.post(`/challenge/api/v1/challenge/${id}/mailing`).then((response) => {
        _callback(response);
    });
}

export function addPlanToUser(plan: IUserPlan, _callback: (response: any) => void) {
    API.post(`/challenge/api/v1/plan/user`, plan).then((response) => {
        _callback(response);
    });
}

export function getExternalIntegrations(_callback: (response: any) => void) {
    API.get(`/challenge/api/v1/integrations`).then((response) => {
        _callback(response);
    });
}

export function getISpringContentList(_callback: (response: any) => void) {
    API.get(`/challenge/api/v1/integrations/ispring`).then((response) => {
        _callback(response);
    });
}

export function deleteStageVideo(challengeId: number, stageId: number, videoId: string, _callback: (response: any) => void) {
    API.delete(`/challenge/api/v1/challenge/${challengeId}/stage/${stageId}/video/${videoId}`).then((response) => {
        _callback(response);
    });
}

export function viewStageVideo(challengeId: number, stageId: number, videoId: string, _callback: (response: any) => void) {
    API.post(`/challenge/api/v1/challenge/${challengeId}/stage/${stageId}/view/${videoId}`).then((response) => {
        _callback(response);
    });
}

export function resetTest(challengeId: number, stageId: number, csrId: number, _callback: (response: any) => void) {
    API.delete(`/challenge/api/v1/test/${challengeId}/${stageId}/reset/${csrId}`).then((response) => {
        _callback(response);
    });
}

export function getChallengeCategories(_callback: (response: any) => void) {
    API.get(`/challenge/api/v1/challenge/category`).then((response) => {
        _callback(response);
    });
}

export function deleteStageCtfTask(challengeId: number, stageId: number, taskId: string, _callback: (response: any) => void) {
    API.delete(`/challenge/api/v1/challenge/${challengeId}/stage/${stageId}/task/${taskId}`).then((response) => {
        _callback(response);
    });
}

export function getSolutions(challengeId: number, stageId: number, _callback: (response: any) => void) {
    API.get(`/challenge/api/v1/challenge/${challengeId}/stage/${stageId}/solution`).then((response) => {
        _callback(response);
    });
}

export function submitSolution(challengeId: number, stageId: number, solution: IChallengeStageSolution, _callback: (response: any) => void) {
    API.post(`/challenge/api/v1/challenge/${challengeId}/stage/${stageId}/solution`, solution).then((response) => {
            _callback(response);
    });
}

export function updatePublicProtocol(challengeId: number, stageId: number, _callback: (response: any) => void) {
    API.post(`/challenge/api/v1/challenge/${challengeId}/stage/${stageId}/update_public_protocol`).then((response) => {
        _callback(response);
    });
}

export function addComment(challengeId: number, stageId: number, resultId: number, comment: any, _callback: (response: any) => void) {
    API.post(`/challenge/api/v1/challenge/${challengeId}/stage/${stageId}/result/${resultId}/comment`, comment).then((response) => {
        _callback(response);
    });
}

export function getDashboard(stageId: number, _callback: (response: any) => void) {
    API.get(`/challenge/api/v1/ctf/${stageId}/board`).then((response) => {
        _callback(response);
    });
}

export function getJuryList(challengeId: number, _callback: (response: AxiosResponse<IJury[]>) => void) {
    API.get(`/challenge/api/v1/challenge/${challengeId}/jury`).then((response) => {
        _callback(response);
    });
}

export function getJury(challengeId: number, juryId: string, _callback: (response: any) => void) {
    API.get(`/challenge/api/v1/challenge/${challengeId}/jury/${juryId}`).then((response) => {
        _callback(response);
    });
}

export function createJury(challengeId: number, request: ICreateJuryRequest, _callback: (response: any) => void) {
    API.post(`/challenge/api/v1/challenge/${challengeId}/jury`, request).then((response) => {
        _callback(response);
    });
}

export function deleteJury(challengeId: number, juryId: string, _callback: (response: any) => void) {
    API.delete(`/challenge/api/v1/challenge/${challengeId}/jury/${juryId}`).then((response) => {
        _callback(response);
    });
}

export function updateJuryStages(challengeId: number, juryId: string, stageIds: number[], _callback: (response: any) => void) {
    API.post(`/challenge/api/v1/challenge/${challengeId}/jury/${juryId}/stages`, stageIds).then((response) => {
        _callback(response);
    });
}

export function getAssessmentSettings(challengeId: number, stageId: number, _callback: (response: any) => void) {
    API.get(`/challenge/api/v1/challenge/${challengeId}/stage/${stageId}/assessment/settings`).then((response) => {
        _callback(response);
    });
}

export function saveAssessmentSettings(challengeId: number, stageId: number, settings: IAssessmentSettings, _callback: (response: any) => void) {
    API.post(`/challenge/api/v1/challenge/${challengeId}/stage/${stageId}/assessment/settings`, settings).then((response) => {
        _callback(response);
    });
}

export function getAssessmentList(challengeId: number, stageId: number, page: number, search: string, filter: string, _callback: (response: any) => void) {
    API.get(`/challenge/api/v1/challenge/${challengeId}/stage/${stageId}/assessment/settings/list?page=${page}&search=${search}&filter=${filter}`).then((response) => {
        _callback(response);
    });
}

export function updateAssessmentList(challengeId: number, stageId: number, request: any, _callback: (response: any) => void) {
    API.post(`/challenge/api/v1/challenge/${challengeId}/stage/${stageId}/assessment/settings/list`, Object.fromEntries(request)).then((response) => {
        _callback(response);
    });
}

export function createMentor(request: ICreateMentorRequest, _callback: (response: any) => void) {
    API.post(`/challenge/api/v1/mentor`, request).then((response) => {
        _callback(response);
    });
}

export function deleteMentor(challengeId: number, _callback: (response: any) => void) {
    API.delete(`/challenge/api/v1/mentor/challenge/${challengeId}`).then((response) => {
        _callback(response);
    });
}

export function getMentorChallenges(page: number, search: string, _callback: (response: any) => void) {
    API.get(`/challenge/api/v1/mentor/challenges?page=${page}&search=${search}`).then((response) => {
        _callback(response);
    });
}

export function getMentorChallenge(challengeId: number, _callback: (response: any) => void) {
    API.get(`/challenge/api/v1/mentor/challenge/${challengeId}`).then((response) => {
        _callback(response);
    });
}

export function getMentorProfile(challengeId: number, _callback: (response: any) => void) {
    API.get(`/challenge/api/v1/mentor/challenge/${challengeId}/my`).then((response) => {
        _callback(response);
    });
}

export function getJuryAssessmentList(challengeId: number, stageId: number, page: number, search: string, filter: string, _callback: (response: AxiosResponse<IJuryAssessmentList>) => void) {
    API.get(`/challenge/api/v1/challenge/${challengeId}/stage/${stageId}/assessment/jury/list?page=${page}&search=${search}&filter=${filter}`).then((response) => {
        _callback(response);
    });
}

export function getAssessment(challengeId: number, stageId: number, assessmentId: string, _callback: (response: any) => void) {
    API.get(`/challenge/api/v1/challenge/${challengeId}/stage/${stageId}/assessment/jury/grades/${assessmentId}`).then((response) => {
        _callback(response);
    });
}

export function submitAssessment(challengeId: number, stageId: number, assessment: IJuryAssessment, _callback: (response: any) => void) {
    API.post(`/challenge/api/v1/challenge/${challengeId}/stage/${stageId}/assessment/jury/grades`, assessment).then((response) => {
        _callback(response);
    });
}

export function submitAllAssessments(challengeId: number, stageId: number, _callback: (response: any) => void) {
    API.post(`/challenge/api/v1/challenge/${challengeId}/stage/${stageId}/assessment/jury/submit`).then((response) => {
        _callback(response);
    });
}

export function redeemAssessment(challengeId: number, stageId: number, _callback: (response: any) => void) {
    API.post(`/challenge/api/v1/challenge/${challengeId}/stage/${stageId}/assessment/jury/redeem`).then((response) => {
        _callback(response);
    });
}

export function updateSolutionPosition(challengeId: number, stageId: number, request: any, _callback: (response: any) => void) {
    API.put(`/challenge/api/v1/challenge/${challengeId}/stage/${stageId}/assessment/settings/solution`, request).then((response) => {
        _callback(response);
    });
}

export function unloadMentorChallenge(challengeId: number, _callback: (status: any) => void) {
    API.get(`/challenge/api/v1/xls/mentor/challenge/${challengeId}`).then((response) => {
        if (response.status === 200) {
            const a = document.createElement('a');
            a.download = `${new Date().toISOString().split('T')[0]}_participants.xlsx`;
            a.href = `data:application/vnd.ms-excel;base64,${response.data.fileBase64}`;
            a.click();
        }
        _callback(response);
    })
}

export function unloadMentors(password: string, _callback: (status: number) => void) {
    API.post(`/challenge/api/v1/xls/mentors`,
        {"username": SessionModel.getCurrentUserAuthData().sub, "password": password},
        {headers: {"Authorization": "Bearer " + localStorage.getItem("token")}}).then((response) => {
        if (response.status === 200) {
            const a = document.createElement('a');
            a.download = `${new Date().toISOString().split('T')[0]}_mentors.xlsx`;
            a.href = `data:application/vnd.ms-excel;base64,${response.data.fileBase64}`;
            a.click();
        }
        _callback(response.status);
    })
}

export function updateSubRoles(request: IGrantRoleRequest, _callback: (response: any) => void) {
    API.post(`/challenge/api/v1/sub-role`, request).then((response) => {
        _callback(response);
    });
}

export function getAuthorities(_callback: (response: any) => void) {
    API.get(`/challenge/api/v1/sub-role/authorities`).then((response) => {
        _callback(response);
    });
}

export function banChallengeUser(challengeId: number, request: IBanRequest, _callback: (response: any) => void) {
    API.post(`/challenge/api/v1/challenge/${challengeId}/user/ban`, request).then((response) => {
        _callback(response);
    });
}