import React, {useEffect, useState} from "react";
import API from "../requests/_axios";
import {useNavigate, useSearchParams} from "react-router-dom";
import {ProfileIndividualDetailsEditor} from "./profile/common/ProfileIndividualDetailsEditor";
import SessionModel from "../stores/SessionModel";
import {SignupSecondStep} from "./SignupSecondStep";
import {NotFound} from "./NotFound";
import {SignupFirstStep} from "./SignupFirstStep";
import {IProfilePersonalForm} from "./types/IProfilePersonalForm";

interface Props {
  type: string;
}

export const FullSignup = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [repassword, setRepassword] = useState("");
  const [signupInProgress, setSignupInProgress] = useState(false);
  const [mailing, setMailing] = useState(false);
  const [politics, setPolitics] = useState(false);
  const [currentForm, setCurrentForm] = useState<IProfilePersonalForm | undefined>(undefined);
  const [searchParams, setSearchParams] = useSearchParams();
  let navigate = useNavigate();

  const PASSWD_PATTERN = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/

  const handleUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  }

  const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  }

  const handleRepassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRepassword(event.target.value);
  }

  const handleMailing = () => {
    setMailing(!mailing);
  }

  const handlePolitics = () => {
    setPolitics(!politics);
  }

  const handleSignup = () => {
    if (password.length < 8) {
      alert("Пароль должен быть не короче 8 символов. При создании пароля рекомендуем использовать латинские строчные и заглавные буквы, цифры и специальные символы");
      return;
    }
    if(password !== repassword) {
      alert("Пароли не совпадают");
      return;
    }
    if (!politics || !mailing) {
      alert("Вы должны согласиться с правилами и политикой конфиденциальности");
      return;
    }
    setSignupInProgress(true);

    API.post<any>('/sso/api/v1/signup', {username: username, password: password, type: "CHANGEME", mailing: mailing, personalDataPolitics: politics}).then((response) => {
      if(response.status === 200) {
        alert("На вашу почту отправлена ссылка для подтверждения регистрации");
        navigate('/');
      } else {
        console.log(response.data.code);
        if(response.data.code === "001-007")
          alert("Неправильный E-mail");
        else if(response.data.code === "001-000")
          alert("Пользователь с таким E-mail уже зарегистрирован");
        else if(response.data.code === "001-009")
          alert("Такой логин уже существует");
        // @ts-ignore
        setSignupInProgress(false);
      }
    });
  }

  if (searchParams.get("step") === "1") {
    return (
        <SignupFirstStep/>
    )
  } else if (searchParams.get("step") === "2") {
    return (
        <SignupSecondStep/>
    )
  } else if (searchParams.get("step") === "3") {
    return (
        <div className="platform-background">
          <video autoPlay muted loop playsInline className="image-back-video">
            <source src={require("../../public/images/background.mp4").default}/>
          </video>
          <div className="reg-anketa-block">
            <header className="header">
              <img src="/images/logo_plat.svg" alt="" className="header-logo"/>
              <div className="username">
                <p className="fio-or-name">Анкета</p>
              </div>
            </header>
            <div className="menu-anketa">
              <ul className="number-registration">
                <li className="number-registration-item">
                  <p className="number-reg">1</p>
                </li>
                <li className="number-registration-line"></li>
                <li className="number-registration-item">
                  <p className="number-reg">2</p>
                </li>
                <li className="number-registration-line"></li>
                <li className="number-registration-item-active">
                  <p className="number-reg-active">3</p>
                </li>
              </ul>
              <ProfileIndividualDetailsEditor currentForm={undefined} userId={SessionModel.getCurrentUserAuthData().id} clazzName={"right-menu-anketa"}/>
            </div>
          </div>
        </div>
    )
  } else {
    return (
        <SignupFirstStep/>
    )
  }
  /**/

  /*
  )*/
}